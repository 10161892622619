@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Bold.eot');
  src: url('../fonts/Comfortaa-Bold.eot?#iefix') format("embedded-opentype"), url('../fonts/Comfortaa-Bold.woff2') format("woff2"), url('../fonts/Comfortaa-Bold.woff') format("woff"), url('../fonts/Comfortaa-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-SemiBold.eot');
  src: url('../fonts/Comfortaa-SemiBold.eot?#iefix') format("embedded-opentype"), url('../fonts/Comfortaa-SemiBold.woff2') format("woff2"), url('../fonts/Comfortaa-SemiBold.woff') format("woff"), url('../fonts/Comfortaa-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Regular.eot');
  src: url('../fonts/Comfortaa-Regular.eot?#iefix') format("embedded-opentype"), url('../fonts/Comfortaa-Regular.woff2') format("woff2"), url('../fonts/Comfortaa-Regular.woff') format("woff"), url('../fonts/Comfortaa-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.eot');
  src: url('../fonts/Inter-Regular.eot?#iefix') format("embedded-opentype"), url('../fonts/Inter-Regular.woff2') format("woff2"), url('../fonts/Inter-Regular.woff') format("woff"), url('../fonts/Inter-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
